// ContactPage.jsx
import React, { useState } from 'react';
import './styles/ContactForm.css';

// Social Media Icons
const socialIcons = [
  {
    name: 'Facebook',
    icon: 'https://img.icons8.com/color/48/000000/facebook-new.png',
    link: 'https://facebook.com'
  },
  {
    name: 'Twitter',
    icon: 'https://img.icons8.com/color/48/000000/twitter.png',
    link: 'https://twitter.com'
  },
  {
    name: 'LinkedIn',
    icon: 'https://img.icons8.com/color/48/000000/linkedin.png',
    link: 'https://linkedin.com'
  },
  {
    name: 'Instagram',
    icon: 'https://img.icons8.com/color/48/000000/instagram-new.png',
    link: 'https://instagram.com'
  }
];

const ContactPage = () => {
  const [formData, setFormData] = useState({
    inquiryType: 'Talent Hiring',
    fullName: '',
    email: '',
    phone: '',
    company: '',
    message: '',
    privacyPolicy: false,
    newsletter: false
  });

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: '8bdfefa4-7335-4f64-9015-bf156e78821b',
          ...formData
        }),
      });

      const data = await response.json();
      if (data.success) {
        setStatus('success');
        setFormData({
          inquiryType: 'Talent Hiring',
          fullName: '',
          email: '',
          phone: '',
          company: '',
          message: '',
          privacyPolicy: false,
          newsletter: false
        });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
    setLoading(false);
  };

  return (
    <div className="page-wrapper">
      {/* Social Media Sidebar */}
      <div className="social-sidebar">
        {socialIcons.map((social) => (
          <a 
            key={social.name}
            href={social.link}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <img src={social.icon} alt={social.name} />
          </a>
        ))}
      </div>

      <div className="content-container">
        <div className="form-section">
          <h1>How can we help?</h1>
          <p className="subtitle">
            Tell us about your inquiry and we'll get back to you as soon as we can.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="options-grid">
              {[
                ['Talent Hiring', 'Enterprise Solutions'],
                ['Web & App Development', 'Creative Design'],
                ['Careers', 'Partners & Investors'],
                ['Press', 'Other']
              ].map((row, idx) => (
                <div key={idx} className="option-row">
                  {row.map((option) => (
                    <label key={option} className="radio-container">
                      <input
                        type="radio"
                        name="inquiryType"
                        value={option}
                        checked={formData.inquiryType === option}
                        onChange={handleInputChange}
                      />
                      <span className="radio-label">{option}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>

            <div className="input-group">
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="input-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="input-group phone-group">
              <select className="country-code" defaultValue="+94">
                <option value="+93">🇦🇫 +93 (Afghanistan)</option>
                <option value="+355">🇦🇱 +355 (Albania)</option>
                <option value="+213">🇩🇿 +213 (Algeria)</option>
                <option value="+1-684">🇦🇸 +1-684 (American Samoa)</option>
                <option value="+376">🇦🇩 +376 (Andorra)</option>
                <option value="+244">🇦🇴 +244 (Angola)</option>
                <option value="+1-264">🇦🇮 +1-264 (Anguilla)</option>
                <option value="+672">🇦🇶 +672 (Antarctica)</option>
                <option value="+1-268">🇦🇬 +1-268 (Antigua & Barbuda)</option>
                <option value="+54">🇦🇷 +54 (Argentina)</option>
                <option value="+374">🇦🇲 +374 (Armenia)</option>
                <option value="+297">🇦🇼 +297 (Aruba)</option>
                <option value="+61">🇦🇺 +61 (Australia)</option>
                <option value="+43">🇦🇹 +43 (Austria)</option>
                <option value="+994">🇦🇿 +994 (Azerbaijan)</option>
                <option value="+1-242">🇧🇸 +1-242 (Bahamas)</option>
                <option value="+973">🇧🇭 +973 (Bahrain)</option>
                <option value="+880">🇧🇩 +880 (Bangladesh)</option>
                <option value="+1-246">🇧🇧 +1-246 (Barbados)</option>
                <option value="+375">🇧🇾 +375 (Belarus)</option>
                <option value="+32">🇧🇪 +32 (Belgium)</option>
                <option value="+501">🇧🇿 +501 (Belize)</option>
                <option value="+229">🇧🇯 +229 (Benin)</option>
                <option value="+1-441">🇧🇲 +1-441 (Bermuda)</option>
                <option value="+975">🇧🇹 +975 (Bhutan)</option>
                <option value="+591">🇧🇴 +591 (Bolivia)</option>
                <option value="+387">🇧🇦 +387 (Bosnia & Herzegovina)</option>
                <option value="+267">🇧🇼 +267 (Botswana)</option>
                <option value="+55">🇧🇷 +55 (Brazil)</option>
                <option value="+673">🇧🇳 +673 (Brunei)</option>
                <option value="+359">🇧🇬 +359 (Bulgaria)</option>
                <option value="+226">🇧🇫 +226 (Burkina Faso)</option>
                <option value="+257">🇧🇮 +257 (Burundi)</option>
                <option value="+855">🇰🇭 +855 (Cambodia)</option>
                <option value="+237">🇨🇲 +237 (Cameroon)</option>
                <option value="+1">🇨🇦 +1 (Canada)</option>
                <option value="+238">🇨🇻 +238 (Cape Verde)</option>
                <option value="+236">🇨🇫 +236 (Central African Republic)</option>
                <option value="+235">🇹🇩 +235 (Chad)</option>
                <option value="+56">🇨🇱 +56 (Chile)</option>
                <option value="+86">🇨🇳 +86 (China)</option>
                <option value="+57">🇨🇴 +57 (Colombia)</option>
                <option value="+506">🇨🇷 +506 (Costa Rica)</option>
                <option value="+385">🇭🇷 +385 (Croatia)</option>
                <option value="+53">🇨🇺 +53 (Cuba)</option>
                <option value="+357">🇨🇾 +357 (Cyprus)</option>
                <option value="+420">🇨🇿 +420 (Czech Republic)</option>
                <option value="+45">🇩🇰 +45 (Denmark)</option>
                <option value="+20">🇪🇬 +20 (Egypt)</option>
                <option value="+33">🇫🇷 +33 (France)</option>
                <option value="+49">🇩🇪 +49 (Germany)</option>
                <option value="+91">🇮🇳 +91 (India)</option>
                <option value="+62">🇮🇩 +62 (Indonesia)</option>
                <option value="+98">🇮🇷 +98 (Iran)</option>
                <option value="+964">🇮🇶 +964 (Iraq)</option>
                <option value="+353">🇮🇪 +353 (Ireland)</option>
                <option value="+972">🇮🇱 +972 (Israel)</option>
                <option value="+81">🇯🇵 +81 (Japan)</option>
                <option value="+962">🇯🇴 +962 (Jordan)</option>
                <option value="+7">🇷🇺 +7 (Russia)</option>
                <option value="+94" selected>🇱🇰 +94 (Sri Lanka)</option>
                <option value="+27">🇿🇦 +27 (South Africa)</option>
                <option value="+34">🇪🇸 +34 (Spain)</option>
                <option value="+41">🇨🇭 +41 (Switzerland)</option>
                <option value="+44">🇬🇧 +44 (United Kingdom)</option>
                <option value="+1">🇺🇸 +1 (United States)</option>
              </select>
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              <input
                type="text"
                name="company"
                placeholder="Company (optional)"
                value={formData.company}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
              <p className='checkbox-label'>*If your county not in the list. Select (+1) and enter the phone number with your country code.</p>
            <div className="checkbox-group">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  name="privacyPolicy"
                  checked={formData.privacyPolicy}
                  onChange={handleInputChange}
                  required
                />
                <span className="checkmark"></span>
                <span className="checkbox-label">
                  I have read and understood the <a href="/privacy-policy">Privacy Policy</a>
                </span>
              </label>

              <label className="checkbox-container">
                <input
                  type="checkbox"
                  name="newsletter"
                  checked={formData.newsletter}
                  onChange={handleInputChange}
                />
                <span className="checkmark"></span>
                <span className="checkbox-label">
                  Receive newsletters about CodeClouds' products and services
                </span>
              </label>
            </div>

            <button id='contact-btn' type="submit" className={loading ? 'loading' : ''} disabled={loading}>
              {loading ? 'Sending...' : 'Submit'}
            </button>

            {status && (
              <div className={`status-message ${status}`}>
                {status === 'success' 
                  ? 'Thank you! Your message has been sent successfully.' 
                  : 'Something went wrong. Please try again.'}
              </div>
            )}
          </form>
        </div>

        {/* Google Map Section */}
        <div className="map-section">
        <iframe
        title="Meegha Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126743.43160410806!2d79.77397316858502!3d6.921837564005847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25933f185e72b%3A0x6c969a31997e89e!2sColombo%2C%20Sri%20Lanka!5e0!3m2!1sen!2s!4v1708784000000!5m2!1sen!2s"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        ></iframe>

        </div>
      </div>
    </div>
  );
};

export default ContactPage;