// TechStack.jsx
import React from 'react';
import { motion } from 'framer-motion';
import './styles/TechStack.css';

const techStacks = [
  { 
    name: 'Python', 
    icon: 'https://img.icons8.com/color/96/python--v1.png'
  },
  { 
    name: 'Java', 
    icon: 'https://img.icons8.com/color/96/java-coffee-cup-logo--v1.png'
  },
  { 
    name: 'Node.js', 
    icon: 'https://img.icons8.com/fluency/96/node-js.png'
  },
  { 
    name: 'R', 
    icon: 'https://img.icons8.com/fluency/96/r-project.png'
  },
  { 
    name: 'C++', 
    icon: 'https://img.icons8.com/color/96/c-plus-plus-logo.png'
  },
  { 
    name: 'Go', 
    icon: 'https://img.icons8.com/color/96/golang.png'
  },
  { 
    name: 'Android', 
    icon: 'https://img.icons8.com/color/96/android-os.png'
  },
  { 
    name: 'iOS', 
    icon: 'https://img.icons8.com/glyph-neue/64/mac-os.png'
  },
  { 
    name: 'Swift', 
    icon: 'https://img.icons8.com/color/96/swift.png'
  },
  { 
    name: 'Flutter', 
    icon: 'https://img.icons8.com/color/96/flutter.png'
  },
  { 
    name: 'Kotlin', 
    icon: 'https://img.icons8.com/color/96/kotlin.png'
  },
  { 
    name: 'JavaScript', 
    icon: 'https://img.icons8.com/color/96/javascript.png'
  },
  { 
    name: 'React', 
    icon: 'https://img.icons8.com/color/96/react-native.png'
  },
  { 
    name: 'Angular', 
    icon: 'https://img.icons8.com/color/96/angularjs.png'
  },
  { 
    name: 'Vue.js', 
    icon: 'https://img.icons8.com/color/96/vue-js.png'
  },
  { 
    name: 'jQuery', 
    icon: 'https://img.icons8.com/ios-filled/96/jquery.png'
  },
  { 
    name: 'TypeScript', 
    icon: 'https://img.icons8.com/color/96/typescript.png'
  },
  { 
    name: 'AWS', 
    icon: 'https://img.icons8.com/color/96/amazon-web-services.png'
  },
  { 
    name: 'Azure', 
    icon: 'https://img.icons8.com/fluency/96/azure-1.png'
  },
  { 
    name: 'Google Cloud', 
    icon: 'https://img.icons8.com/color/96/google-cloud.png'
  },
  { 
    name: 'Kubernetes', 
    icon: 'https://img.icons8.com/color/96/kubernetes.png'
  },
  { 
    name: 'Docker', 
    icon: 'https://img.icons8.com/color/96/docker.png'
  },
  { 
    name: 'MongoDB', 
    icon: 'https://img.icons8.com/color/96/mongodb.png'
  },
  { 
    name: 'PostgreSQL', 
    icon: 'https://img.icons8.com/color/48/postgreesql.png'
  },
  { 
    name: 'MySQL', 
    icon: 'https://img.icons8.com/color/96/mysql-logo.png'
  },
  { 
    name: 'Selenium', 
    icon: 'https://img.icons8.com/color/96/selenium-test-automation.png'
  },
  { 
    name: 'Jenkins', 
    icon: 'https://img.icons8.com/color/96/jenkins.png'
  },
  { 
    name: 'Shopify', 
    icon: 'https://img.icons8.com/color/96/shopify.png'
  },
  { 
    name: 'WordPress', 
    icon: 'https://img.icons8.com/color/96/wordpress.png'
  },
  { 
    name: 'Magento', 
    icon: 'https://img.icons8.com/color/96/magento.png'
  },
  { 
    name: 'HubSpot', 
    icon: 'https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo.png'
  },
  { 
    name: 'Salesforce', 
    icon: 'https://img.icons8.com/color/96/salesforce.png'
  },
  { 
    name: 'Netlify', 
    icon: 'https://img.icons8.com/external-tal-revivo-color-tal-revivo/96/external-netlify-a-cloud-computing-company-that-offers-hosting-and-serverless-backend-services-for-static-websites-logo-color-tal-revivo.png'
  },
  { 
    name: 'DigitalOcean',
    icon: 'https://i.ibb.co/QvHXK3p4/icons8-digitalocean-50.png'
  }
];

const TechStack = () => {
  return (
    <section className="tech-stack">
      <div className="tech-stack-container">
        <div className="services-header">
                  <h2>TechStacks</h2>
                  <p>Whether it's one person or an entire team, we have a range of experts
                  who can build your digital software solution right.</p>
                </div>
        
        <div className="tech-grid">
          {techStacks.map((tech, index) => (
            <motion.div
              key={tech.name}
              className="tech-item"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.5,
                delay: index * 0.1 
              }}
            >
              <div className="tech-icon-wrapper">
                <img src={tech.icon} alt={tech.name} />
                <span className="tooltip">{tech.name}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TechStack;