import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import anime from 'animejs';
import './styles/Home.css';
import eye from '../assets/eye.jpg';

const Home = () => {
  const gridRef = useRef(null);
  
  useEffect(() => {
    // Create grid background
    const createGrid = () => {
      const gridContainer = gridRef.current;
      if (!gridContainer) return;
      
      // Clear any existing grid
      gridContainer.innerHTML = '';
      
      // Grid dimensions
      const columns = 12;
      const rows = 8;
      
      // Create grid cells
      for (let i = 0; i < columns * rows; i++) {
        const cell = document.createElement('div');
        cell.className = 'grid-cell';
        gridContainer.appendChild(cell);
      }
      
      // Apply grid styling to container
      gridContainer.style.display = 'grid';
      gridContainer.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
      gridContainer.style.gridTemplateRows = `repeat(${rows}, 1fr)`;
      
      return {
        cells: gridContainer.querySelectorAll('.grid-cell'),
        columns,
        rows
      };
    };
    
    // Animate grid on click
    const handleClick = (event) => {
      const grid = createGrid();
      if (!grid) return;
      
      anime({
        targets: grid.cells,
        scale: [
          {value: 0.1, easing: 'easeOutSine', duration: 500},
          {value: 1, easing: 'easeInOutQuad', duration: 1200}
        ],
        backgroundColor: [
          {value: 'rgba(37, 99, 235, 0.1)', easing: 'easeOutSine', duration: 500},
          {value: 'rgba(192, 237, 255, 0.12)', easing: 'easeInOutQuad', duration: 1200},
          {value: 'rgba(37, 99, 235, 0)', easing: 'easeInOutQuad', duration: 1200}
        ],
        borderRadius: [
          {value: '0%', easing: 'easeOutSine', duration: 500},
          {value: '50%', easing: 'easeInOutQuad', duration: 1200},
          {value: '0%', easing: 'easeInOutQuad', duration: 1200}
        ],
        delay: anime.stagger(100, {
          grid: [grid.columns, grid.rows],
          from: 'center'
        }),
        easing: 'easeInOutQuad'
      });
    };
    
    // Initial grid creation
    createGrid();
    
    // Add click event
    document.addEventListener('click', handleClick);
    
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="home">
      <div className="grid-background" ref={gridRef}></div>
      
      <main className="main-content">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="hero-title"
        >
           Connect.
          <span className="highlight">  Create.</span>
          <br />
          Inspire.
        </motion.h1>
      </main>
      
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="glass-card"
      >
        <div className="card-content">
          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="image-button"
          >
            <img
              src={eye}
              alt="Featured project"
              className="project-image"
            />
            <div className="image-overlay">
              <span className="view-project">View Project →</span>
            </div>
          </motion.div>
          
          <div className="description">
            <h3 className="description-title">Meegha</h3>
            <p className="description-text">
              Meegha is a Sri Lanka-based web and software development company delivering innovative and scalable digital solutions.
            </p>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="cta-button-home"
            >
              Learn More
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;