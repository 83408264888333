import React from 'react';
import { motion } from 'framer-motion';
import './styles/About.css';
import Logo from '../assets/img/logoblack.png';

const About = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const statVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.8 
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="about">
      <motion.div 
        className="about__container"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={containerVariants}
      >
        <motion.div 
          className="about__card main-card"
          variants={cardVariants}
        >
          <div className="about__image">
            <motion.img 
              src={Logo} 
              alt="Company Logo"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            />
          </div>
          <div className="about__content">
            <motion.h1 
              className="about__title"
              variants={cardVariants}
            >
              About Us
            </motion.h1>
            <motion.p 
              className="about__subtitle"
              variants={cardVariants}
            >
              Connect. Create. Inspire
            </motion.p>
            <motion.p 
              className="about__text"
              variants={cardVariants}
            >
              We are a forward-thinking company dedicated to innovation and excellence. 
              Our mission is to create meaningful solutions that drive positive change in the world.
            </motion.p>
            <motion.div 
              className="about__stats"
              variants={containerVariants}
            >
              <motion.div className="stat" variants={statVariants}>
                <span className="stat__number">2+</span>
                <span className="stat__label">Years Experience</span>
              </motion.div>
              <motion.div className="stat" variants={statVariants}>
                <span className="stat__number">40+</span>
                <span className="stat__label">Projects Completed</span>
              </motion.div>
              <motion.div className="stat" variants={statVariants}>
                <span className="stat__number">15+</span>
                <span className="stat__label">Team Members</span>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>

        <motion.div 
          className="about__cards"
          variants={containerVariants}
        >
          <motion.div 
            className="about__card"
            variants={cardVariants}
          >
            <h2 className="card__title">Our Vision</h2>
            <p className="card__text">
              To become the leading innovator in sustainable technology solutions, 
              creating a positive impact on businesses and communities worldwide.
            </p>
          </motion.div>

          <motion.div 
            className="about__card"
            variants={cardVariants}
          >
            <h2 className="card__title">Our Values</h2>
            <ul className="card__list">
              <li>Innovation First</li>
              <li>Customer Success</li>
              <li>Sustainability</li>
              <li>Team Excellence</li>
            </ul>
          </motion.div>

          <motion.div 
            className="about__card"
            variants={cardVariants}
          >
            <h2 className="card__title">Our Approach</h2>
            <p className="card__text">
              We combine cutting-edge technology with human-centered design to 
              deliver solutions that exceed expectations and drive real results.
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default About;