import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import TechStack from './components/TechStack';
import Industry from './components/Industry';
import Loader from './components/Loader';
import Contact from './components/Contact';
import Footer from './components/Footer';
import logo from './assets/img/logoblack.png';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const menuVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  const hamburgerLineVariants = {
    closed: {
      rotate: 0,
      y: 0
    },
    open: (i) => ({
      rotate: i === 1 ? 45 : i === 3 ? -45 : 0,
      y: i === 1 ? 8 : i === 3 ? -8 : 0,
      opacity: i === 2 ? 0 : 1,
      transition: { duration: 0.3 }
    })
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <nav className="nav">
        <div className="nav-container">
          <div className="nav-logo">
            <img src={logo} alt="Meegha" style={{width: "3rem" , marginTop: "0.5rem"}} />
          </div>

          <div className="nav-links">
            <ul>
              <motion.li
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                <a href="#about">About</a>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <a href="#services">Services</a>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <a href="https://careers.meegha.com">Careers</a>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <a href="#industry">Industry</a>
              </motion.li>
            </ul>
            
            <motion.div
              className="nav-btn"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <a href="#contact">Contact</a>
            </motion.div>
          </div>

          <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
            {[1, 2, 3].map((i) => (
              <motion.span
                key={i}
                custom={i}
                variants={hamburgerLineVariants}
                animate={isOpen ? "open" : "closed"}
                style={{
                  width: "24px",
                  height: "2px",
                  backgroundColor: "var(--blue-700)",
                  display: "block"
                }}
              />
            ))}
          </button>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="mobile-menu"
              variants={menuVariants}
              initial="closed"
              animate="open"
              exit="closed"
            >
              <ul>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  <a href="#about">About</a>
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  
                  <a href="#careers">Careers</a>
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <a href="https://careers.meegha.com">Services</a>
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                >
                  <a href="#industry">Industry</a>
                </motion.li>
                <motion.li
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="nav-btn">
                    <a href="#contact" style={{color: '#fff'}}>Contact</a>
                  </div>
                </motion.li>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

      <Home />
      <section id="about">
        <About />
      </section> 
      <section id="services">
        <Services />
      </section>
      <section id="tech-stack">
        <TechStack />
      </section>
      <section id="industry">
        <Industry />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default App;