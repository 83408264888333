// ServiceSection.jsx
import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import anime from 'animejs';
import './styles/ServiceSection.css';

const services = [
  {
    id: 1,
    icon: '💻',
    title: 'Web Development',
    description: 'Custom responsive websites built with React, Next.js, and modern frameworks.',
    features: ['Responsive Design', 'CMS Integration', 'E-commerce Solutions']
  },
  {
    id: 2,
    icon: '📱',
    title: 'Mobile App Development',
    description: 'Native and cross-platform mobile applications that deliver seamless experiences.',
    features: ['Cross-platform Development', 'Native Features', 'Offline Support']
  },
  {
    id: 3,
    icon: '🎨',
    title: 'UI/UX Design',
    description: 'Intuitive and engaging user interfaces designed with your users in mind.',
    features: ['User Research', 'Wireframing', 'Interactive Prototypes']
  },
  {
    id: 4,
    icon: '📊',
    title: 'Data Analytics',
    description: 'Transform your raw data into actionable insights with custom analytics.',
    features: ['Custom Dashboards', 'Predictive Analytics', 'Data Visualization']
  },
  {
    id: 5,
    icon: '🛠️',
    title: 'Custom Software Development',
    description: 'Tailored software solutions to meet your unique business needs.',
    features: ['Bespoke Solutions', 'Scalable Architecture', 'Maintenance & Support']
  },
  {
    id: 6,
    icon: '☁️',
    title: 'Cloud Solutions',
    description: 'Leverage the power of the cloud for scalable and reliable applications.',
    features: ['Cloud Migration', 'Infrastructure Management', 'Cost Optimization']
  },
  {
    id: 7,
    icon: '🏢',
    title: 'Enterprise Applications',
    description: 'Robust and scalable applications designed for large organizations.',
    features: ['ERP Systems', 'CRM Solutions', 'Business Process Automation']
  },
  {
    id: 8,
    icon: '🔒',
    title: 'Cybersecurity',
    description: 'Protect your business with our comprehensive cybersecurity solutions.',
    features: ['Threat Detection', 'Vulnerability Management', 'Incident Response']
  },
  {
    id: 9,
    icon: '📈',
    title: 'Business Analysis',
    description: 'In-depth analysis to help you understand your business and make informed decisions.',
    features: ['SWOT Analysis', 'Business Process Modeling', 'Requirements Gathering']
  }
];

const serviceKeywords = [
  ...services.map(service => service.title)
];

const ServiceSection = () => {
  const gridRef = useRef(null);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  // Grid Animation Setup
  useEffect(() => {
    // Create grid points
    const GRID_WIDTH = 25;
    const GRID_HEIGHT = 15;
    const GRID_SIZE = GRID_WIDTH * GRID_HEIGHT;
    
    // Create grid points HTML
    const gridPoints = Array.from({ length: GRID_SIZE }).map((_, index) => {
      const point = document.createElement('div');
      point.className = 'grid-point';
      return point;
    });
    
    // Clear and append new points
    if (gridRef.current) {
      gridRef.current.innerHTML = '';
      gridPoints.forEach(point => gridRef.current.appendChild(point));
    }

    // Animate grid points
    const animation = anime({
      targets: '.grid-point',
      scale: [
        { value: 1, duration: 0 },
        { value: 1.5, duration: 2000 },
        { value: 1, duration: 2000 }
      ],
      opacity: [
        { value: 0.5, duration: 0 },
        { value: 1, duration: 2000 },
        { value: 0.5, duration: 2000 }
      ],
      delay: anime.stagger(200, {
        grid: [GRID_WIDTH, GRID_HEIGHT],
        from: 'center',
        axis: 'x'
      }),
      loop: true,
      direction: 'alternate',
      easing: 'easeInOutQuad'
    });

    return () => animation.pause();
  }, []);

  // Content Animation
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const ServiceCard = ({ service, index }) => {
    const cardControls = useAnimation();
    const [cardRef, cardInView] = useInView({
      threshold: 0.2,
      triggerOnce: true
    });

    useEffect(() => {
      if (cardInView) {
        cardControls.start('visible');
      }
    }, [cardControls, cardInView]);

    return (
      <motion.div
        ref={cardRef}
        initial={{ opacity: 0, y: 50 }}
        animate={cardControls}
        variants={{
          visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, delay: index * 0.1 }
          }
        }}
        className="service-card"
      >
        <div className="service-icon">{service.icon}</div>
        <h3 className="service-title">{service.title}</h3>
        <p className="service-description">{service.description}</p>
        <ul className="service-features">
          {service.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        
      </motion.div>
    );
  };

  const InfiniteSlider = () => {
    const doubledKeywords = [...serviceKeywords, ...serviceKeywords];
    
    return (
      <div className="infinite-slider-container">
        <motion.div
          className="infinite-slider"
          animate={{
            x: [0, -50 * serviceKeywords.length],
          }}
          transition={{
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 20,
              ease: "linear",
            },
          }}
        >
          {doubledKeywords.map((keyword, index) => (
            <div key={index} className="slider-item">
              <span>{keyword}</span>
              <span className="slider-divider">•</span>
            </div>
          ))}
        </motion.div>
      </div>
    );
  };

  return (
    <section id="services" className="services-section">
      <div ref={gridRef} className="grid-background"></div>
      
      <div className="services-container">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: -30 }}
          animate={controls}
          variants={{
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 0.6 }
            }
          }}
          className="services-header"
        >
          <h2>Our Services</h2>
          <p>Comprehensive digital solutions crafted to elevate your business</p>
        </motion.div>
        
        <div className="services-grid">
          {services.map((service, index) => (
            <ServiceCard key={service.id} service={service} index={index} />
          ))}
        </div>

        <div className="cta-container">
          <h3>Ready to transform your digital presence?</h3>
          <p>Let's discuss how our services can help you achieve your goals</p>
          <a href='#bookConsultation' style={{ textDecoration: 'none' }} className="cta-button">Schedule a Consultation</a>
        </div>

        <InfiniteSlider />
      </div>
    </section>
  );
};

export default ServiceSection;