import React from 'react';
import { motion } from 'framer-motion';
import './styles/Industries.css';

const industries = [
    {
        name: 'E commerce',
        image: 'https://images.pexels.com/photos/34577/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        bio: 'E-commerce involves buying and selling goods and services online.'
    },
    {
        name: 'Fintech',
        image: 'https://images.pexels.com/photos/6771607/pexels-photo-6771607.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Fintech is the integration of technology into offerings by financial services companies.'
    },
    {
        name: 'Healthcare',
        image: 'https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Healthcare industry provides medical services, manufactures medical equipment or drugs.'
    },
    {
        name: 'Travel & hospitality',
        image: 'https://images.pexels.com/photos/1287460/pexels-photo-1287460.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Travel and hospitality industry focuses on services related to travel and lodging.'
    },
    {
        name: 'Real estate',
        image: 'https://images.pexels.com/photos/87223/pexels-photo-87223.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Real estate involves buying, selling, and managing properties.'
    },
    {
        name: 'Logistic',
        image: 'https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Logistics industry manages the flow of goods from origin to consumption.'
    },
    {
        name: 'Telecom',
        image: 'https://images.pexels.com/photos/1624895/pexels-photo-1624895.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Telecom industry provides communication services such as phone and internet.'
    },
    {
        name: 'Public Service',
        image: 'https://images.pexels.com/photos/6646917/pexels-photo-6646917.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Public service involves government services provided to the public.'
    },
    {
        name: 'Web3',
        image: 'https://images.pexels.com/photos/6771665/pexels-photo-6771665.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Web3 represents the next generation of the internet, focusing on decentralization.'
    },
    {
        name: 'Government',
        image: 'https://images.pexels.com/photos/208755/pexels-photo-208755.jpeg?auto=compress&cs=tinysrgb&w=600',
        bio: 'Government sector involves the administration and regulation of public policy.'
    }
];

const Industry = () => {
    const headerVariants = {
        hidden: { opacity: 0, y: -100 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: "spring",
                stiffness: 50,
                damping: 20,
                duration: 1
            }
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const cardVariants = {
        hidden: { 
            opacity: 0,
            y: 50,
            scale: 0.9
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 12,
                duration: 0.8
            }
        },
        hover: {
            scale: 1.05,
            rotateY: 2,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 10
            }
        }
    };

    return (
        <div className="industry-section">
            <motion.div 
                className="services-header"
                variants={headerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-100px" }}
            >
                <h2>Industries</h2>
                <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    Empowering businesses across sectors with innovative digital solutions. 
                    Our expertise spans multiple industries, delivering tailored technology 
                    that drives growth and transformation.
                </motion.p>
            </motion.div>
            
            <motion.div 
                className="industry-container"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, margin: "-50px" }}
            >
                {industries.map((industry, index) => (
                    <motion.div
                        key={index}
                        className="industry-card"
                        variants={cardVariants}
                        whileHover="hover"
                        custom={index}
                        viewport={{ once: true }}
                    >
                        <motion.div 
                            className="card-content"
                            whileHover={{ 
                                boxShadow: "0 10px 40px rgba(31, 38, 135, 0.15)",
                            }}
                        >
                            <motion.div 
                                className="image-container"
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 300 }}
                            >
                                <img src={industry.image} alt={industry.name} />
                            </motion.div>
                            <h3>{industry.name}</h3>
                            <p>{industry.bio}</p>
                        </motion.div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default Industry;