import React from 'react';
import { color, motion } from 'framer-motion';
import './styles/Footer.css';

const Footer = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0
    }
  };

  return (
    <motion.footer 
      className="footer-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="footer-content">
        <motion.div className="company-info" variants={itemVariants}>
          <h2>Meegha</h2>
          <p>Connect. Create.
          Inspire.</p>
        </motion.div>

        <motion.div className="footer-links" variants={itemVariants}>
          <div className="link-column">
            <h3>Company</h3>
            <a href="#about">About Us</a>
            <a href="#services">Services</a>
            <a href="https://careers.meegha.com">Careers</a>
            <a href="#contact">Contact</a>
            
          </div>
          <div className="link-column">
            <h3>Services</h3>
            <a href="#industry">Industry</a>
            <a href="#services">Consultation</a>
            <a href="mailto: hello@meegha.com">Support</a>
          </div>
        </motion.div>

        <motion.div className="social-links" variants={itemVariants}>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/facebook-new.png" alt="Facebook"/>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/twitter.png" alt="Twitter"/>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/linkedin.png" alt="LinkedIn"/>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="https://img.icons8.com/color/48/000000/instagram-new.png" alt="Instagram"/>
          </a>
        </motion.div>
      </div>

      <motion.div className="footer-bottom" variants={itemVariants}>
    <p>&copy; 2025 <a href='/' style={{color:"#6ba5ff" , textDecoration: "none"}}>Meegha</a>. All rights reserved.</p>
      </motion.div>
    </motion.footer>
  );
};

export default Footer;